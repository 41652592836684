import React, { useEffect } from "react";

const GREETING = `
             ++@@++
       @++ @@ +++   *@
       @@  @@   +++@@@@@
       @@  @@  ++  @@  @@
       @@@@@@  ++  @@  @@
       @@  @@  ++  @@  @@
       @@  @@  ++  @@  @@
       @@  @@  ++  @@  @@
 ++++++@@          @@@@++++++++
 
If you're interest about us,
Join HireDiversity Team!

link: https://hirediversity.club/`;

function App() {
  useEffect(() => {
    console.log(GREETING);
  }, []);

  return <HireDiversityClub />;
}

function HireDiversityKr() {
  return (
    <>
      <head>
        <meta charSet="utf-8"></meta>
        <meta name="viewport" content="width=device-width"></meta>

        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="https://profiles.greetinghr.com/group/e0e57e38-d531-4d72-877c-58a2e317e9d6"
        ></link>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://profiles.greetinghr.com/group/e0e57e38-d531-4d72-877c-58a2e317e9d6"
        ></link>
        <link
          rel="apple-touch-icon"
          href="https://profiles.greetinghr.com/group/e0e57e38-d531-4d72-877c-58a2e317e9d6"
          sizes="76x76"
        ></link>

        <meta name="googlebot" content="notranslate"></meta>
        <meta name="robots" content="index"></meta>

        <meta name="title" content="하이어다이버시티 | hirediversity"></meta>
        <meta
          name="description"
          content="국내 1위 외국인 유학생 전용 플랫폼, 주식회사 하이어다이버시티입니다."
        ></meta>

        <meta property="og:type" content="website"></meta>
        <meta
          property="og:site_name"
          content="하이어다이버시티 | hirediversity"
        ></meta>
        <meta
          property="og:title"
          content="하이어다이버시티 | hirediversity"
        ></meta>
        <meta
          property="og:description"
          content="국내 1위 외국인 유학생 전용 플랫폼, 주식회사 하이어다이버시티입니다."
        ></meta>
        <meta
          property="og:image"
          content="https://opening-attachments.greetinghr.com/2023-12-20/86263130-1f4d-4b4e-9816-2b8090a105bb/hiredirversity.png"
        ></meta>

        <meta
          property="twitter:title"
          content="하이어다이버시티 | hirediversity"
        ></meta>
        <meta
          property="twitter:description"
          content="국내 1위 외국인 유학생 전용 플랫폼, 주식회사 하이어다이버시티입니다."
        ></meta>
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta
          property="twitter:url"
          content="https://hirediversity.career.greetinghr.com"
        ></meta>
        <meta
          property="twitter:image"
          content="https://opening-attachments.greetinghr.com/2023-12-20/ac48fc2d-cafc-4052-bbfd-0afcbcec6dea/hiredirversity.png"
        ></meta>

        <meta
          name="keywords"
          content="채용, recruit, 면접, 인터뷰, 지원, 스타트업, 대기업, 회사, 직군, 신입, 경력, 무관, 정규직, 계약직, 대규모 채용, 신입채용, 경력채용, 무관채용, 계약직채용, 정규직채용, 채용공고, 수시채용, 인터뷰, 주식회사 하이어다이버시티채용, 주식회사 하이어다이버시티"
        ></meta>

        <title>하이어다이버시티 | hirediversity</title>
      </head>

      <body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 0,
          margin: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <iframe
          id="hirevisa-com-iframe"
          title="하이어다이버시티 | hirediversity"
          src="https://www.hirevisa.com/membership/jobboard"
          style={{
            width: "100%",
            height: "100%",
            padding: 0,
            margin: 0,
            border: 0,
          }}
        ></iframe>
      </body>
    </>
  );
}

function HireDiversityClub() {
  return (
    <>
      <head>
        <meta charSet="utf-8"></meta>
        <meta name="viewport" content="width=device-width"></meta>

        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="https://profiles.greetinghr.com/group/e0e57e38-d531-4d72-877c-58a2e317e9d6"
        ></link>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://profiles.greetinghr.com/group/e0e57e38-d531-4d72-877c-58a2e317e9d6"
        ></link>
        <link
          rel="apple-touch-icon"
          href="https://profiles.greetinghr.com/group/e0e57e38-d531-4d72-877c-58a2e317e9d6"
          sizes="76x76"
        ></link>

        <meta name="googlebot" content="notranslate"></meta>
        <meta name="robots" content="index"></meta>

        <meta name="title" content="하이어다이버시티 | hirediversity"></meta>
        <meta
          name="description"
          content="국내 1위 외국인 유학생 전용 플랫폼, 주식회사 하이어다이버시티입니다."
        ></meta>

        <meta property="og:type" content="website"></meta>
        <meta
          property="og:site_name"
          content="하이어다이버시티 | hirediversity"
        ></meta>
        <meta
          property="og:title"
          content="하이어다이버시티 | hirediversity"
        ></meta>
        <meta
          property="og:description"
          content="국내 1위 외국인 유학생 전용 플랫폼, 주식회사 하이어다이버시티입니다."
        ></meta>
        <meta
          property="og:image"
          content="https://opening-attachments.greetinghr.com/2023-12-20/86263130-1f4d-4b4e-9816-2b8090a105bb/hiredirversity.png"
        ></meta>

        <meta
          property="twitter:title"
          content="하이어다이버시티 | hirediversity"
        ></meta>
        <meta
          property="twitter:description"
          content="국내 1위 외국인 유학생 전용 플랫폼, 주식회사 하이어다이버시티입니다."
        ></meta>
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta
          property="twitter:url"
          content="https://hirediversity.career.greetinghr.com"
        ></meta>
        <meta
          property="twitter:image"
          content="https://opening-attachments.greetinghr.com/2023-12-20/ac48fc2d-cafc-4052-bbfd-0afcbcec6dea/hiredirversity.png"
        ></meta>

        <meta
          name="keywords"
          content="채용, recruit, 면접, 인터뷰, 지원, 스타트업, 대기업, 회사, 직군, 신입, 경력, 무관, 정규직, 계약직, 대규모 채용, 신입채용, 경력채용, 무관채용, 계약직채용, 정규직채용, 채용공고, 수시채용, 인터뷰, 주식회사 하이어다이버시티채용, 주식회사 하이어다이버시티"
        ></meta>

        <title>하이어다이버시티 | hirediversity</title>
      </head>

      <body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 0,
          margin: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <iframe
          id="hirediversity-club-iframe"
          title="하이어다이버시티 | hirediversity"
          src="https://hirediversity.career.greetinghr.com"
          style={{
            width: "100%",
            height: "100%",
            padding: 0,
            margin: 0,
            border: 0,
          }}
        ></iframe>
      </body>
    </>
  );
}

export default App;
